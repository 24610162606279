require("aos/dist/aos.css");
const AOS = require("aos");

// addEventListener("DOMContentLoaded", (event) => {
setTimeout(() => {
  AOS.init({
    disable: false,
    startEvent: "DOMContentLoaded",
    initClassName: "aos-init",
    animatedClassName: "aos-animate",
    useClassNames: false,
    disableMutationObserver: false,
    debounceDelay: 50,
    throttleDelay: 99,

    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 120,
    delay: 0,
    duration: 700,
    easing: "ease-in-out-quad",
    mirror: false,
    anchorPlacement: "top-bottom",
    once: true,
  });
}, 0);
// });

require("../css/main.css");
import i18next from "i18next";
import enMainResources from "../i18n/main.en.json";
import esMainResources from "../i18n/main.es.json";
import enIndexResources from "../i18n/index.en.json";
import esIndexResources from "../i18n/index.es.json";
import enAboutUsResources from "../i18n/about-us.en.json";
import esAboutUsResources from "../i18n/about-us.es.json";
import enPortfolioResources from "../i18n/portfolio.en.json";
import esPortfolioResources from "../i18n/portfolio.es.json";
import enContactResources from "../i18n/contact.en.json";
import esContactResources from "../i18n/contact.es.json";
import enServicesResources from "../i18n/services.en.json";
import esServicesResources from "../i18n/services.es.json";

const lng = getCookie("lng");
const enLngElement = document.getElementById("en-lng");
const esLngElement = document.getElementById("es-lng");
const enLngMobileElement = document.getElementById("en-lng-mobile");
const esLngMobileElement = document.getElementById("es-lng-mobile");
const enLngFooterElement = document.getElementById("en-lng-footer");
const esLngFooterElement = document.getElementById("es-lng-footer");
const lngOptions = {
  en: "en",
  es: "es",
};

const logoIcon = require("../img/logo.svg");
const logoIconFooter = require("../img/Logo Footer.svg");
const logoLinkedInFooter = require("../img/footer-linkedin.svg");
const navbarImg = document.getElementById("navbar-icon");
const footerImg = document.getElementById("footer-icon");
//const footerImgLinked = document.getElementById("footer-linkedin-icon");

// Set navbar logo
navbarImg.src = logoIcon;
// Set footer logo
footerImg.src = logoIconFooter;
//footerImgLinked.src = logoLinkedInFooter;

const labelsToTranslate = [
  {
    id: "contact-name",
    prop: "placeholder",
    labelName: "contact.contactUs.name",
  },
  {
    id: "contact-email",
    prop: "placeholder",
    labelName: "contact.contactUs.email",
  },
  {
    id: "contact-companyName",
    prop: "placeholder",
    labelName: "contact.contactUs.companyName",
  },
  {
    id: "contact-phoneNumber",
    prop: "placeholder",
    labelName: "contact.contactUs.phoneNumber",
  },
  {
    id: "contact-country",
    prop: "placeholder",
    labelName: "contact.contactUs.country",
  },
  {
    id: "contact-interestedIn",
    prop: "placeholder",
    labelName: "contact.contactUs.interestedIn",
  },
  {
    id: "contact-message",
    prop: "placeholder",
    labelName: "contact.contactUs.message",
  },
];

/** Scroll To Top Functionality */

// Get the button
let mybutton = document.getElementById("btn-back-to-top");

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function () {
  scrollFunction();
};

function scrollFunction() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    mybutton.style.display = "block";
  } else {
    mybutton.style.display = "none";
  }
}
// When the user clicks on the button, scroll to the top of the document
mybutton.addEventListener("click", backToTop);

function backToTop() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

/** End of Scroll To Top Functionality */

i18next
  .init({
    lng: lng || lngOptions.en,
    fallbackLng: lngOptions.en,
    resources: {
      [lngOptions.en]: {
        translation: {
          ...enMainResources,
          ...enIndexResources,
          ...enAboutUsResources,
          ...enPortfolioResources,
          ...enContactResources,
          ...enServicesResources,
        },
      },
      [lngOptions.es]: {
        translation: {
          ...esMainResources,
          ...esIndexResources,
          ...esAboutUsResources,
          ...esPortfolioResources,
          ...esContactResources,
          ...esServicesResources,
        },
      },
    },
  })
  .then(() => {
    updateContent();
    updateLngClass(lng);
    setElementStyles(lng);
  });

enLngElement.addEventListener("click", () => changeLng(lngOptions.en));
esLngElement.addEventListener("click", () => changeLng(lngOptions.es));
enLngMobileElement.addEventListener("click", () => changeLng(lngOptions.en));
esLngMobileElement.addEventListener("click", () => changeLng(lngOptions.es));
enLngFooterElement.addEventListener("click", () => changeLng(lngOptions.en));
esLngFooterElement.addEventListener("click", () => changeLng(lngOptions.es));

function updateContent() {
  const labels = document.querySelectorAll("[data-i18n]");

  labels.forEach((label) => {
    const translationKey = label.getAttribute("data-i18n");
    const translationOptions = JSON.parse(
      label.getAttribute("i18n-options") || "{}"
    );
    label.textContent = i18next.t(translationKey, translationOptions);
  });
}

function updateLngClass(currentLng) {
  if (currentLng === lngOptions.en) {
    enLngElement.classList.add("lng-option-active");
    esLngElement.classList.remove("lng-option-active");
    enLngMobileElement.classList.add("lng-option-active");
    esLngMobileElement.classList.remove("lng-option-active");
    enLngFooterElement.classList.add("lng-option-active");
    esLngFooterElement.classList.remove("lng-option-active");
  } else {
    enLngElement.classList.remove("lng-option-active");
    esLngElement.classList.add("lng-option-active");
    enLngMobileElement.classList.remove("lng-option-active");
    esLngMobileElement.classList.add("lng-option-active");
    enLngFooterElement.classList.remove("lng-option-active");
    esLngFooterElement.classList.add("lng-option-active");
  }
}

function getCookie(name) {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + "=")) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
}

function changeLng(currentLng) {
  document.cookie = `lng=${currentLng}`;
  i18next.changeLanguage(currentLng).then(() => {
    updateContent();
    updateLngClass(currentLng);
    setElementStyles(currentLng);
  });
}

function setElementStyles(currentLng) {
  labelsToTranslate.forEach((labelToTranslate) => {
    const element = document.getElementById(labelToTranslate.id);

    if (element) {
      element[labelToTranslate.prop] = i18next.t(
        labelToTranslate.labelName,
        {}
      );
    }
  });
}
